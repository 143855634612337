'use strict';

angular.module('cpformplastApp.jobs')
    .factory('SubjobProductionReport', function() {

    class SubjobProductionReport {
        constructor({_id, boxIndex, createdAt}) {
          this.id = _id;
          this.boxIndex = boxIndex;
          this.createdAt = new Date(createdAt);
        }
    }

    return SubjobProductionReport;
});
